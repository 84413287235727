import { template as template_795a643c6b0649f3808348fffd9a0ef1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_795a643c6b0649f3808348fffd9a0ef1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
