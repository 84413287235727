import { template as template_9be3170daa654336a3d36d90e9548410 } from "@ember/template-compiler";
const WelcomeHeader = template_9be3170daa654336a3d36d90e9548410(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
