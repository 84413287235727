import { template as template_78ab8fe1d80c46b79f6725a24b2bdd98 } from "@ember/template-compiler";
const FKLabel = template_78ab8fe1d80c46b79f6725a24b2bdd98(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
