import { template as template_33a91764c5df48e89fefe481c8bb11df } from "@ember/template-compiler";
const FKControlMenuContainer = template_33a91764c5df48e89fefe481c8bb11df(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
